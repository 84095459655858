import { createState } from "state-pool";
import { useEffect } from "react";
import { FilterElement, FilterSortOption, FilterSortOrder } from "../../models/FilterElement";

export const useCurrentPrivateFilterElements = createState<FilterElement[]>(() => {
  const filterElementsString = localStorage.getItem("currentFilterElements");
  if (filterElementsString != null) {
    return JSON.parse(filterElementsString) ?? [];
  }
  return [];
});

export const useCurrentPrivateFilterSortOption = createState<FilterSortOption>(() => {
  const filterSortOptionString = localStorage.getItem("currentFilterSortOption");
  if (filterSortOptionString != null) {
    return JSON.parse(filterSortOptionString) ?? FilterSortOption.ARTIST;
  }
  return FilterSortOption.ARTIST;
});

export const useCurrentPrivateFilterSortOrder = createState<FilterSortOrder>(() => {
  const filterSortOrderString = localStorage.getItem("currentFilterSortOrder");
  if (filterSortOrderString != null) {
    return JSON.parse(filterSortOrderString) ?? FilterSortOrder.ASCENDANT;
  }
  return FilterSortOrder.ASCENDANT;
});

export const useCurrentPrivateSearchText = createState<string>(() => {
  return localStorage.getItem("currentSearchText");
});

export const useCurrentLayoutFilterElements = createState<FilterElement[]>(() => {
  const filterElementsString = localStorage.getItem("currentLayoutFilterElements");
  if (filterElementsString != null) {
    return JSON.parse(filterElementsString) ?? [];
  }
  return [];
});

export const useCurrentLayoutFilterSortOption = createState<FilterSortOption>(() => {
  const filterSortOptionString = localStorage.getItem("currentLayoutFilterSortOption");
  if (filterSortOptionString != null) {
    return JSON.parse(filterSortOptionString) ?? FilterSortOption.ARTIST;
  }
  return FilterSortOption.ARTIST;
});

export const useCurrentLayoutFilterSortOrder = createState<FilterSortOrder>(() => {
  const filterSortOrderString = localStorage.getItem("currentLayoutFilterSortOrder");
  if (filterSortOrderString != null) {
    return JSON.parse(filterSortOrderString) ?? FilterSortOrder.ASCENDANT;
  }
  return FilterSortOrder.ASCENDANT;
});

export const useCurrentLayoutSearchText = createState<string>(() => {
  return localStorage.getItem("currentLayoutSearchText");
});

export const useAlbumFilterManager = (isLayout: boolean) => {
  const [currentFilterElements] = isLayout
    ? useCurrentLayoutFilterElements.useState()
    : useCurrentPrivateFilterElements.useState();
  const [currentFilterSortOption] = isLayout
    ? useCurrentLayoutFilterSortOption.useState()
    : useCurrentPrivateFilterSortOption.useState();
  const [currentFilterSortOrder] = isLayout
    ? useCurrentLayoutFilterSortOrder.useState()
    : useCurrentPrivateFilterSortOrder.useState();
  const [currentSearchText] = isLayout
    ? useCurrentLayoutSearchText.useState()
    : useCurrentPrivateSearchText.useState();
  useEffect(() => {
    const key = isLayout ? "currentLayoutFilterElements" : "currentFilterElements";
    if (currentFilterElements.length == 0) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(currentFilterElements));
    }
  }, [currentFilterElements]);
  useEffect(() => {
    const key = isLayout ? "currentLayoutFilterSortOption" : "currentFilterSortOption";
    if (currentFilterSortOption == FilterSortOption.ARTIST) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(currentFilterSortOption));
    }
  }, [currentFilterSortOption]);
  useEffect(() => {
    const key = isLayout ? "currentLayoutFilterSortOrder" : "currentFilterSortOrder";
    if (currentFilterSortOrder == FilterSortOrder.ASCENDANT) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(currentFilterSortOrder));
    }
  }, [currentFilterSortOrder]);
  useEffect(() => {
    const key = isLayout ? "currentLayoutSearchText" : "currentSearchText";
    if (!currentSearchText || currentSearchText == "") {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, currentSearchText);
    }
  }, [currentSearchText]);
  const getCurrentFilterElements = (isPublic: boolean) => {
    return isPublic ? [] : currentFilterElements;
  };
  const getCurrentFilterSortOption = (isPublic: boolean) => {
    return isPublic ? FilterSortOption.ARTIST : currentFilterSortOption;
  };
  const getCurrentFilterSortOrder = (isPublic: boolean) => {
    return isPublic ? FilterSortOrder.ASCENDANT : currentFilterSortOrder;
  };
  const getCurrentSearchText = (isPublic: boolean) => {
    return isPublic ? "" : currentSearchText;
  };
  return {
    getCurrentFilterElements,
    getCurrentFilterSortOption,
    getCurrentFilterSortOrder,
    getCurrentSearchText
  };
};

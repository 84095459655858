import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useTheme } from "@mui/material";
import { ImageUtilities } from "../../models/ImageUtilities";

export const LazyImageAspectFit: FunctionComponent<ILazyImageAspectFitProps> = ({
  className,
  imageClassName,
  style,
  thumbnail,
  image,
  defaultImage,
  beforeLoad,
  imageStyle,
  alt,
  canMagnify,
  forceHorizontal,
  forceVertical,
  tintColor,
  waitUntilImageIsFinal
}) => {
  const theme = useTheme();
  const [isSpine, setIsSpine] = useState(false);
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [finalImage, setFinalImage] = useState(defaultImage);
  const [isImageFinal, setIsImageFinal] = useState(false);
  const magnifierHeight = 200;
  const magnifierWidth = 200;
  const zoomLevel = 3;
  const updateDimensions = (source: string) => {
    const image = new Image();
    image.src = source;
    image.onload = function () {
      if (forceVertical && image.height < image.width) {
        setIsSpine(true);
        setFinalImage(ImageUtilities.rotate(image, 90));
      } else if (forceHorizontal && image.height > image.width) {
        setIsSpine(false);
        setFinalImage(ImageUtilities.rotate(image, -90));
      } else {
        setIsSpine(image.height > image.width);
        setFinalImage(source);
      }
      setIsImageFinal(true);
    };
  };
  useEffect(() => {
    const imageData = image ? `data:image/png;base64,${image}` : undefined;
    const thumbnailData = thumbnail ? `data:image/png;base64,${thumbnail}` : undefined;
    updateDimensions(imageData ?? thumbnailData ?? defaultImage);
  }, [image, thumbnail, defaultImage, forceHorizontal, forceVertical]);
  return (
    <div
      className={classNames(styles.container, className)}
      style={{
        opacity: !waitUntilImageIsFinal || isImageFinal ? 1 : 0,
        backgroundColor: "transparent",
        cursor: canMagnify ? "none" : "auto",
        ...style
      }}
    >
      <LazyLoadComponent
        style={{ display: "flex" }}
        beforeLoad={beforeLoad}
        placeholder={
          <img
            className={classNames(isSpine ? styles.spineImage : styles.image, imageClassName)}
            style={imageStyle}
            src={thumbnail ? `data:image/png;base64,${thumbnail}` : defaultImage}
            alt={alt}
          />
        }
      >
        <img
          className={classNames(isSpine ? styles.spineImage : styles.image, imageClassName)}
          style={imageStyle}
          src={finalImage}
          alt={alt}
          onMouseEnter={event => {
            const element = event.currentTarget;
            const { width, height } = element.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
          }}
          onMouseMove={event => {
            const element = event.currentTarget;
            const { top, left } = element.getBoundingClientRect();
            const x = event.pageX - left - window.scrollX;
            const y = event.pageY - top - window.scrollY;
            setXY([x, y]);
          }}
          onMouseLeave={() => setShowMagnifier(false)}
        />
        {tintColor && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: tintColor,
              opacity: 0.5
            }}
          />
        )}
        {canMagnify && (
          <div
            style={{
              display: showMagnifier ? "" : "none",
              position: "absolute",
              pointerEvents: "none",
              height: `${magnifierHeight}px`,
              width: `${magnifierWidth}px`,
              top: `${y - magnifierHeight / 2}px`,
              left: `${x - magnifierWidth / 2}px`,
              opacity: "1",
              border: `1px solid ${theme.palette.secondary.main}`,
              backgroundColor: theme.palette.background.default,
              backgroundImage: `url(data:image/png;base64,${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
              backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
              backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
            }}
          ></div>
        )}
      </LazyLoadComponent>
    </div>
  );
};

export interface ILazyImageAspectFitProps {
  className?: string;
  imageClassName?: string;
  style?: CSSProperties;
  thumbnail?: string | null;
  base64?: string;
  image?: string;
  defaultImage: string;
  beforeLoad?: () => void;
  imageStyle?: CSSProperties;
  alt: string;
  canMagnify?: boolean;
  forceHorizontal?: boolean;
  forceVertical?: boolean;
  tintColor?: string;
  waitUntilImageIsFinal?: boolean;
}

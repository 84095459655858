import styles from "./styles.module.scss";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Box, Card, CardActionArea, Stack, useTheme } from "@mui/material";
import { IAlbum } from "../../../hooks/albums";
import { IUserProfile } from "../../../hooks/userProfiles";
import { useGetImage } from "../../../hooks/images";
import { ImageCache } from "../../../models/ImageCache";
import { StatusCodesHelper } from "../../../models/StatusCodesHelper";
import genericImage from "../../../images/generic_sleeve.jpg";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import { UserAvatar } from "../../UserAvatar";
import { AlbumUtilities } from "../../../models/AlbumUtilities";
import { LazyImageAspectFit } from "../../LazyImageAspectFit";
import { useUserProfile } from "../../../hooks/session";
import { useCurrencyFormatter } from "../../../hooks/currencyFormatter";
import { ICollection } from "../../../hooks/collections";

export const PublicAlbumCard: FunctionComponent<IPublicAlbumCardProps> = ({
  album,
  collection,
  userProfile,
  onClick
}) => {
  const theme = useTheme();
  const [currentUserProfile] = useUserProfile.useState();
  const currencyFormatter = useCurrencyFormatter();
  const { getImage } = useGetImage();
  const [image, setImage] = useState();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [albumSubtitle, setAlbumSubtitle] = useState("");
  const fetchImage = async () => {
    if (!album.hasImage) return;
    setIsLoadingImage(true);
    const response = await getImage({
      name: album.uuid,
      width: ImageCache.bigAlbumImageSize,
      height: ImageCache.bigAlbumImageSize
    });
    if (StatusCodesHelper.isSuccessful(response.status)) {
      setImage(response.body);
    }
    setIsLoadingImage(false);
  };
  useEffect(() => {
    const subtitle = AlbumUtilities.getSubtitle(album, currentUserProfile);
    setAlbumSubtitle(subtitle);
    fetchImage().then();
  }, [album]);
  return (
    <Card
      className={styles.card}
      sx={{
        display: "flex"
      }}
    >
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
        onClick={onClick}
      >
        <div className={styles.image}>
          {album.sellStatus && !["new", "highlighted"].includes(album.sellStatus) && (
            <Box
              className={styles.reservedBox}
              style={{
                backgroundColor: AlbumUtilities.getSellStatusBackgroundColor(album.sellStatus)
              }}
            >
              <p className={styles.reservedText}>
                {AlbumUtilities.getSellStatusDescription(album.sellStatus)}
              </p>
            </Box>
          )}
          <LazyImageAspectFit
            className={styles.image}
            image={image}
            thumbnail={album.thumbnail}
            defaultImage={genericImage}
            beforeLoad={fetchImage}
            alt={album.name}
          />
          {isLoadingImage && (
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                backdropFilter: "blur(10px)"
              }}
            >
              <CustomLoadingIndicator />
            </div>
          )}
        </div>
        <Stack className={styles.mainStack}>
          <p className={styles.name}>{album.name}</p>
          <p className={styles.subtitle} style={{ color: theme.palette.primary.subtitle }}>
            <b>{album.artist.name}</b>
            {` ${albumSubtitle}`}
          </p>
          <Box flexGrow={1} />
          <Stack direction={"row"}>
            {album.sellValue?.pesos && (
              <p className={styles.amount}>
                {currencyFormatter.format(
                  (album.sellValue?.pesos ?? 0) * (1 - collection.discount),
                  userProfile,
                  true
                )}
              </p>
            )}
            <div style={{ display: "flex", alignSelf: "end", alignItems: "center" }}>
              <UserAvatar className={styles.avatar} userProfile={userProfile} />
              <p className={styles.username}>{userProfile.username}</p>
            </div>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export interface IPublicAlbumCardProps {
  album: IAlbum;
  collection: ICollection;
  userProfile: IUserProfile;
  onClick: () => void;
}

export const doc_user_profile_es = {
  title: "Perfil de usuario",
  home_title: "Perfil de usuario",
  description: `Podés personalizar tu perfil de usuario seleccionando la tarjeta de perfil en la esquina superior derecha de la pantalla, o seleccionando la opción desde el menú desplegable.
  
`,
  notifications_title: "Notificaciones",
  notifications_description: `Dentro de la página de perfil de usuario vas a encontrar el botón de notificaciones. Ahí vas a encontrar todas las notificaciones oficiales que recibiste, acerca de información en general, cambios de precios, o noticias de última hora.
  
`,
  update_avatar_title: "Actualizar tu avatar",
  update_avatar_description: `Para actualizar tu avatar simplemente seleccionalo. El sistema te va a pedir una nueva imagen. La imagen debe tener formato JPEG o PNG, y su tamaño no debe superar los 10MiB.
  
`,
  username_title: "Actualizar tu nombre de usuario",
  username_description: `Después de crear tu cuenta, tu nombre de usuario es igual a tu correo electrónico. Podés cambiarlo dentro de la página de perfil de usuario. El nuevo nombre de usuario tiene que tener entre 8 y 20 caracteres, tiene que empezar con una letra y no puede tener caracteres especiales excepto guiones bajos.
  
`,
  email_title: "Actualizar tu correo electrónico",
  email_description: `Podés cambiar tu correo electrónico actual dentro de la página de perfil de usuario. Ingresá la nueva dirección de correo electrónico en el último campo y luego seleccioná el botón de guardar. En ese momento el nuevo correo queda pendiente de confirmación. Si pasó un tiempo desde tu último ingreso a la aplicación, el sistema puede requerir que ingreses tus credenciales otra vez.

  En esta etapa, tu correo electrónico todavía no cambió, para terminar el proceso tenés que seleccionar el botón de verificar e ingresar el código que enviamos a tu nueva dirección de correo electrónico. Una vez que el código está verificado, el nuevo correo va a reemplazar el actual y el proceso quedará terminado.
  
`,
  password_title: "Actualizar tu contraseña",
  password_description: `Seleccioná el campo de constraseña dentro de la página de perfil de usuario para cambiarla por una nueva. Un nuevo diálogo va a pedirte ingresar la contraseña actual y la nueva que vas a tener que repetir para evitar errores de tipeo. Si pasó un tiempo desde tu último ingreso a la aplicación, el sistema puede requerir que ingreses tus credenciales otra vez.
  
`,
  bio_title: "Biografía",
  bio_description: `Podés ingresar cualquier información sobre vos o tu colección en tu biografía. Ésta va a estar disponible para todos los usuarios en la Exhibición. Si buscás tu nombre de usuario en la Exhibición y seleccionás tu perfil vas a poder ver tu perfil público y qué es lo que todo el mundo puede ver sobre vos.
  
`,
  currency_title: "Moneda",
  currency_description: `Dentro de tu perfil de usuario vas a poder cambiar tu moneda. Esto va a afectar a toda tu colección y todas tus colecciones públicas pasarán a usar la nueva moneda para mostrar cualquier monto que hayas decidido exponer. Tené en cuenta que cambiar la moneda no va a actualizar los montos de ninguna forma, solamente el símbolo y código de moneda mostrado. Los valores van a seguir siendo calculados según tus cambios de moneda.
  
  Esta moneda va a ser también la especificada en todos tus cambios de moneda, pero tené en cuenta que cambiar la moneda no va a actualizar los montos de los cambios de moneda de ninguna forma, solamente el símbolo y código de moneda.
  
`,
  decimals_title: "Decimales en importes financieros",
  decimals_description: `Con esta opción podés decidir si querés que se muestren decimales en los montos mostrados con tu moneda.
  
`,
  impacts_description_title: "Mostrar descripción de calificaciones",
  impacts_description_description: `Con esta opción podés decidir si querés que se muestren las calificaciones con sus descripciones además de las estrellas, o solo las estrellas.
  
`,
  album_id_title: "Mostrar ID del álbum",
  album_id_description: `Si activás esta opción se va a mostrar el identificador interno de cada álbum al fondo de su detalle. Podés usar esto como referencia personal o para referirte a un álbum en específico cuando contactes a soporte.
  
`,
  colors_title: "Colores de discos sin color",
  colors_description: `Ingresá acá todos los colores que no quieras que cuenten como 'Álbumes de color' en tus estadísticas. Ingresá individualmente cada color exactamente como los ingresaste en los álbumes.
    
`,
  countries_title: "Países para ocultar de la lista de álbumes",
  countries_description: `Ingresá acá todos los países que no quieras que se muestren en la lista de álbumes. Estos países van a seguir mostrándose en el detalle del álbum.
    
`
};

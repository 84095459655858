import React, { FunctionComponent, useState } from "react";
import { ITrack } from "../../../hooks/albums";
import { Box, Button, Card, CardActionArea, Divider, List, Stack, Tooltip } from "@mui/material";
import { TrackListItem } from "../../attributes/TrackListItem";
import { CustomLoadingIndicator } from "../../CustomLoadingIndicator";
import styles from "./styles.module.scss";
import { strings } from "../../../localization/LocalizedStrings";
import { TrackListDialog } from "../../attributes/TrackListDialog";
import { toast } from "react-toastify";
import { ResponsiveIcon } from "../../ResponsiveIcon";
import { YouTube } from "@mui/icons-material";
import { useIsMobile } from "../../../hooks/isMobile";
import { useGetVideoIds } from "../../../hooks/youTube/useGetVideoIds";

export const AlbumTrackList: FunctionComponent<IAlbumTrackListProps> = ({
  isPublic,
  className,
  trackList,
  artistName,
  albumName,
  fetchFromDiscogsEnabled,
  isLoading,
  onChange,
  onPlayFromYouTube,
  onFetchTrackListFromDiscogs
}) => {
  const isMobile = useIsMobile();
  const getVideoIds = useGetVideoIds();
  const [isFetchingVideoIds, setIsFetchingVideoIds] = useState(false);
  const [isTrackListDialogOpen, setIsTrackListDialogOpen] = useState(false);
  return (
    <Card
      elevation={5}
      className={className}
      sx={{ display: "flex", flexDirection: "column", alignSelf: isMobile ? "center" : "stretch" }}
    >
      <Stack direction={"row"} style={{ width: "100%", alignItems: "center" }}>
        <CardActionArea
          className={styles.trackListTitle}
          disabled={isPublic}
          onClick={() => {
            setIsTrackListDialogOpen(true);
          }}
        >
          <p className={styles.title}>{strings.track_list_title}</p>
          <Box flexGrow={1} />
        </CardActionArea>
        {isFetchingVideoIds || isLoading ? (
          <Button className={styles.playButton}>
            <CustomLoadingIndicator className={styles.loadingIndicator} />
          </Button>
        ) : (
          trackList &&
          trackList.length > 0 && (
            <Tooltip title={strings.play_from_youtube} arrow disableInteractive>
              <Button
                className={styles.playButton}
                onClick={async () => {
                  setIsFetchingVideoIds(true);
                  const videoIds = await getVideoIds(trackList, albumName, artistName);
                  setIsFetchingVideoIds(false);
                  if (videoIds.length > 0) {
                    onPlayFromYouTube(videoIds);
                  } else {
                    toast.error(strings.player_unknown_error);
                  }
                }}
              >
                <ResponsiveIcon icon={YouTube} />
              </Button>
            </Tooltip>
          )
        )}
      </Stack>
      {trackList && trackList.length > 0 && <Divider variant={"middle"} />}
      <List className={styles.list} disablePadding>
        {!isLoading &&
          trackList?.map((track, index) => (
            <div key={index}>
              <TrackListItem
                track={track}
                artistName={artistName}
                albumName={albumName}
                onPlayFromYouTube={videoId => onPlayFromYouTube([videoId])}
              />
              {index < trackList.length - 1 && <Divider />}
            </div>
          ))}
      </List>
      <TrackListDialog
        open={isTrackListDialogOpen}
        setOpen={setIsTrackListDialogOpen}
        trackList={trackList}
        fetchFromDiscogsEnabled={fetchFromDiscogsEnabled}
        onChange={newTrackList => {
          onChange(newTrackList);
        }}
        onFetchFromDiscogs={() => {
          onFetchTrackListFromDiscogs();
        }}
      />
    </Card>
  );
};

export interface IAlbumTrackListProps {
  isPublic: boolean;
  className?: string;
  trackList?: ITrack[];
  artistName: string;
  albumName: string;
  fetchFromDiscogsEnabled: boolean;
  isLoading: boolean;
  onChange: (newTrackList: ITrack[]) => void;
  onPlayFromYouTube: (videoIds: string[]) => void;
  onFetchTrackListFromDiscogs: () => void;
}

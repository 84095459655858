import { useEffect, useState } from "react";
import { IAlbum } from "./interfaces";
import { arrayMoveMutable } from "array-move";
import { StatusCodesHelper } from "../../models/StatusCodesHelper";
import { FilterElement, FilterSortOption, FilterSortOrder } from "../../models/FilterElement";
import { strings } from "../../localization/LocalizedStrings";
import { AlbumUtilities } from "../../models/AlbumUtilities";
import { TimeInterval } from "../../models/TimeInterval";
import { ICollection } from "../collections";
import { useReorderAlbums } from "./useReorderAlbums";
import { toast } from "react-toastify";
import {
  useCurrentPrivateCollection,
  useCurrentPublicCollection
} from "../collections/useCollectionManager";

export const useAlbumStorage = <T>(
  isActive: boolean,
  isPublic: boolean,
  isFiltering: boolean,
  pageSize: number,
  backendRequest: (
    parameters: IGetAlbumsParameters,
    moreParameters?: any
  ) => Promise<{ body?: any; status: number }>,
  onLoading: (isLoading: boolean) => void,
  onError: (body: any) => void,
  shareLink?: string | null | (string | null)[],
  searchText?: string,
  filterElements?: FilterElement[],
  sortOption?: FilterSortOption,
  sortOrder?: FilterSortOrder,
  dependencies: T[] = []
) => {
  const [albums, setAlbums] = useState<IAlbum[][] | undefined>(undefined);
  const [currentPrivateCollection] = useCurrentPrivateCollection.useState();
  const [currentPublicCollection] = useCurrentPublicCollection.useState();
  const [lastUuid, setLastUuid] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);
  const [noMoreAlbums, setNoMoreAlbums] = useState(false);
  const { reorderAlbums } = useReorderAlbums();
  const sortAlbums = (body: any) => {
    switch (sortOption) {
      case FilterSortOption.ARTIST:
        sortAlbumsByArtist(body);
        break;
      case FilterSortOption.LABEL:
        sortAlbumsByLabel(body);
        break;
      case FilterSortOption.ORIGINAL_YEAR:
        sortAlbumsByOriginalYear(body);
        break;
      case FilterSortOption.RELEASE_YEAR:
        sortAlbumsByReleaseYear(body);
        break;
      case FilterSortOption.STORE:
        sortAlbumsByStore(body);
        break;
      case FilterSortOption.COUNTRY:
        sortAlbumsByCountry(body);
        break;
      case FilterSortOption.PURCHASE_PRICE:
        sortAlbumsByPurchasePrice(body);
        break;
      case FilterSortOption.SELL_VALUE:
        sortAlbumsBySellValue(body);
        break;
      case FilterSortOption.SELL_VALUE_DOLLARS:
        sortAlbumsBySellValueDollars(body);
        break;
      case FilterSortOption.REVENUE:
        sortAlbumsByRevenue(body);
        break;
      case FilterSortOption.PURCHASE_DATE:
        sortAlbumsByPurchaseDate(body);
        break;
      case FilterSortOption.TRACK_COUNT:
        sortAlbumsByTrackCount(body);
        break;
      case FilterSortOption.LENGTH:
        sortAlbumsByLength(body);
        break;
      case FilterSortOption.RANDOM:
        sortAlbumsByRandom(body);
        break;
      case undefined:
        sortAlbumsByArtist(body);
        break;
    }
  };
  const sortAlbumsByArtist = (body: any) => {
    let lastArtist = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastArtist = newAlbums[lastSection - 1][0].artist.uuid;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastArtist != album.artist.uuid) {
        lastArtist = album.artist.uuid;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByRandom = (body: any) => {
    if (albums) return;
    const newAlbums = [];
    newAlbums[0] = body;
    setAlbums(newAlbums);
    setNoMoreAlbums(true);
  };
  const sortAlbumsByLabel = (body: any) => {
    let lastLabel = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastLabel = newAlbums[lastSection - 1][0].label?.uuid ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastLabel != album.label?.uuid) {
        lastLabel = album.label?.uuid ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByOriginalYear = (body: any) => {
    let lastOriginalYear = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastOriginalYear = newAlbums[lastSection - 1][0].originalYear ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastOriginalYear != (album.originalYear ?? strings.unknown)) {
        lastOriginalYear = album.originalYear ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByReleaseYear = (body: any) => {
    let lastReleaseYear = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastReleaseYear = newAlbums[lastSection - 1][0].releaseYear ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastReleaseYear != (album.releaseYear ?? strings.unknown)) {
        lastReleaseYear = album.releaseYear ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByStore = (body: any) => {
    let lastStore = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastStore = newAlbums[lastSection - 1][0].store?.uuid ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastStore != album.store?.uuid) {
        lastStore = album.store?.uuid ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByCountry = (body: any) => {
    let lastCountry = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastCountry = newAlbums[lastSection - 1][0].country ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastCountry != album.country) {
        lastCountry = album.country ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByPurchasePrice = (body: any) => {
    let lastPurchasePrice = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastPurchasePrice =
        newAlbums[lastSection - 1][0].purchasePriceDollars?.toFixed() ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastPurchasePrice != album.purchasePriceDollars?.toFixed()) {
        lastPurchasePrice = album.purchasePriceDollars?.toFixed() ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsBySellValue = (body: any) => {
    let lastSellValue = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastSellValue = newAlbums[lastSection - 1][0].sellValue?.uuid ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastSellValue != album.sellValue?.uuid) {
        lastSellValue = album.sellValue?.uuid ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsBySellValueDollars = (body: any) => {
    let lastDollars = -1;
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastDollars = newAlbums[lastSection - 1][0].sellValue?.dollars ?? 0;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastDollars != album.sellValue?.dollars) {
        lastDollars = album.sellValue?.dollars ?? 0;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByRevenue = (body: any) => {
    let lastRevenue = "";
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastRevenue =
        AlbumUtilities.getRevenue(newAlbums[lastSection - 1][0])?.toFixed() ?? strings.unknown;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastRevenue != (AlbumUtilities.getRevenue(album)?.toFixed() ?? strings.unknown)) {
        lastRevenue = AlbumUtilities.getRevenue(album)?.toFixed() ?? strings.unknown;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByPurchaseDate = (body: any) => {
    let lastPurchaseDate = -1;
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastPurchaseDate =
        TimeInterval.getCanonicalTimestamp(newAlbums[lastSection - 1][0].purchaseDate) ?? 0;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastPurchaseDate !== (TimeInterval.getCanonicalTimestamp(album.purchaseDate) ?? 0)) {
        lastPurchaseDate = TimeInterval.getCanonicalTimestamp(album.purchaseDate) ?? 0;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByTrackCount = (body: any) => {
    let lastTrackCount = -1;
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastTrackCount = newAlbums[lastSection - 1][0].tracks ?? 0;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastTrackCount != (album.tracks ?? 0)) {
        lastTrackCount = album.tracks ?? 0;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  const sortAlbumsByLength = (body: any) => {
    let lastLength = -1;
    let section = -1;
    const lastSection = albums?.length ?? 0;
    const newAlbums = albums ?? [];
    if (lastSection > 0) {
      lastLength = newAlbums[lastSection - 1][0].length ?? 0;
      section = lastSection - 1;
    }
    body.forEach((album: IAlbum) => {
      if (lastLength != (album.length ?? 0)) {
        lastLength = album.length ?? 0;
        section += 1;
      }
      if (newAlbums[section]) {
        newAlbums[section]?.push(album);
      } else {
        newAlbums[section] = [album];
      }
    });
    setAlbums(newAlbums);
  };
  useEffect(() => {
    if (isFetching || noMoreAlbums || !isActive) {
      return;
    }
    if (isPublic) {
      if (!shareLink) return;
      const publicCollection = currentPublicCollection;
      if (publicCollection) {
        fetchAlbums(publicCollection);
      }
    } else {
      fetchAlbums(currentPrivateCollection);
    }
  }, dependencies);
  const fetchAlbums = (collection?: ICollection) => {
    let ignoreResponse = false;
    setIsFetching(true);
    onLoading(true);
    backendRequest(
      {
        lastUuid: lastUuid,
        pageSize,
        collectionId: collection?.uuid,
        isFiltering: isFiltering,
        searchText: searchText
      },
      FilterElement.queryItems(filterElements, sortOption, sortOrder)
    ).then(({ body, status }) => {
      if (ignoreResponse) return;
      if (!StatusCodesHelper.isSuccessful(status)) {
        onLoading(false);
        onError(body);
        setNoMoreAlbums(true);
        setIsFetching(false);
        return;
      }
      if (!body) {
        return;
      }
      if (body.length > 0) {
        setLastUuid(body.slice(-1)[0].uuid);
      }
      setNoMoreAlbums(body.length < pageSize);
      sortAlbums(body);
      setIsFetching(false);
      onLoading(false);
    });
    return () => {
      ignoreResponse = true;
    };
  };
  const resetAlbumStorage = () => {
    setNoMoreAlbums(false);
    setAlbums(undefined);
    setLastUuid(undefined);
  };
  const moveAlbum = async (section: number, source: number, destination: number) => {
    if (!albums) {
      return;
    }
    arrayMoveMutable(albums[section], source, destination);
    onLoading(true);
    const { status } = await reorderAlbums(albums[section]);
    if (StatusCodesHelper.isSuccessful(status)) {
      toast.success(strings.generic_update_success);
      albums[section].forEach((album, index) => {
        album.order = index;
      });
    } else {
      toast.error(strings.album_update_error);
    }
    onLoading(false);
  };
  const deleteAlbum = (section: number, row: number) => {
    if (!albums) {
      return;
    }
    albums[section].splice(row, 1);
    if (albums[section].length == 0) {
      albums.splice(section, 1);
    }
  };
  return [
    albums,
    setAlbums,
    resetAlbumStorage,
    moveAlbum,
    deleteAlbum,
    noMoreAlbums,
    isFetching
  ] as const;
};

export interface IGetAlbumsParameters {
  lastUuid?: string;
  pageSize: number;
  collectionId?: string;
  isFiltering: boolean;
  searchText?: string;
}

export interface IFilterAlbumsParameters {
  isGatefold?: string | string[];
  hasInsert?: string | string[];
  hasLyrics?: string | string[];
  isClean?: string | string[];
  storeId?: string | string[];
  labelId?: string | string[];
  impactId?: string | string[];
  sleeveGradeId?: string | string[];
  mediaGradeId?: string | string[];
  sellValueId?: string | string[];
  artistId?: string | string[];
  albumTypeId?: string | string[];
  albumFormatId?: string | string[];
  tagId?: string | string[];
  country?: string | string[];
  color?: string | string[];
  releaseYear?: string | string[];
  originalYear?: string | string[];
  sellStatus?: string | string[];
  sortOption: string;
  sortOrder: "asc" | "desc";
  isNull?: string;
}
